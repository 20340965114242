// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@600;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  line-height: normal;\n  background-color: black;\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  width: 100%;\n}\n:root {\n  /* fonts */\n  --font-orbitron: Orbitron;\n  --font-open-sans: \"Open Sans\";\n\n  /* Colors */\n  --white-100: #fff;\n  --red-500: #f93f1d;\n  --black-500: #000;\n  --green-500: #cbff5e;\n\n}\n", "",{"version":3,"sources":["webpack://./src/components/global.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;AACA;EACE,UAAU;EACV,yBAAyB;EACzB,6BAA6B;;EAE7B,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;;AAEtB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Orbitron:wght@600;700;900&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap\");\n\nbody {\n  margin: 0;\n  padding: 0;\n  line-height: normal;\n  background-color: black;\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  width: 100%;\n}\n:root {\n  /* fonts */\n  --font-orbitron: Orbitron;\n  --font-open-sans: \"Open Sans\";\n\n  /* Colors */\n  --white-100: #fff;\n  --red-500: #f93f1d;\n  --black-500: #000;\n  --green-500: #cbff5e;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
