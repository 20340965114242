export { default as Checkout } from "./Checkout"
export { default as Desserts } from "./Desserts"
export { default as Drinks } from "./Drinks"
export { default as Home } from "./Home"
export { default as Locations } from "./Locations"
export { default as Login } from "./Login"
export { default as OrderOptions } from "./OrderOptions"
export { default as Pizza } from "./Pizza"
export { default as Sides } from "./Sides"
export { default as Header } from "./Header"
export { default as Footer } from "./Footer"
export { default as Register } from "./Register"
export { default as Menu } from "./Menu"
export { default as Profile } from "./Profile"
export { default as Admin } from "./Admin"
export { default as Adminlogin} from "./Adminlogin"
export { default as Payment } from "./Payment"
